// PROD Config
export const apiUrl = "https://dashboard-api.neoris-eyes.com";
export const simulationQuestionId = "658c3f769519e60037d3a842";

// DEV Config
// export const apiUrl = "https://dev-dashboard-api.neoris-eyes.com";
// export const simulationQuestionId = "658c3f9c9b40e0002aece10d";

// LOCAL DEV Config
// export const apiUrl = "http://localhost:3000";
// export const simulationQuestionId = "N/C";