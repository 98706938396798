// import "./App.css";
import { useEffect, useState } from "react";
import Simulator from "./opencv/Simulator";
import countries from "./assets/countries.json";
import * as fr from "./translations/fr.json";
import * as de from "./translations/de.json";
import * as it from "./translations/it.json";
import * as es from "./translations/es.json";
import { apiUrl, simulationQuestionId } from "./helpers/constants";

function App() {
  const [language, setLanguage] = useState("en");
  const [formData, setFormData] = useState({
    country: "United States of America (the)",
  });
  const [formLoading, setFormLoading] = useState(false);
  const [formSuccess, setFormSuccess] = useState();
  const [showSimulator, setShowSimulator] = useState(localStorage.getItem("simulator-form-submitted") == "1");
  // const [showSimulator, setShowSimulator] = useState(true);

  const t = (str) => {
    switch (language) {
      case "fr":
        return fr[str];
      case "de":
        return de[str];
      case "it":
        return it[str];
      case "es":
        return es[str];
      default:
        return str;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamLanguage = queryParams.get("language");
    setLanguage(queryParamLanguage || "en");
    
    if (queryParamLanguage == "fr") {
      setFormData({ ...formData, country: "France" });
    } else if (queryParamLanguage == "de") {
      setFormData({ ...formData, country: "Germany" });
    } else if (queryParamLanguage == "es") {
      setFormData({ ...formData, country: "Spain" });
    } else if (queryParamLanguage == "it") {
      setFormData({ ...formData, country: "Italy" });
    }
  }, []);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    setFormSuccess(null);
    console.log(formData);

    try {
      const response = await fetch(`${apiUrl}/website/contact-questions`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          language: language,
          question: simulationQuestionId,
          firstname: formData.firstname,
          lastname: formData.lastname,
          email: formData.email,
          phone: formData.phone || "",
          country: formData.country,
        }),
      });

      if (response.ok && response.status >= 200 && response.status <= 299) {
        console.log(response);
        setFormSuccess(t('The simulator is loading...'));
        setTimeout(() => {
          setShowSimulator(true);
        }, 1200);
        localStorage.setItem("simulator-form-submitted", "1");
        if (window.dataLayer) {
          window.dataLayer.push({ 'event': 'cold-prospect-form-submitted' });
          window.dataLayer.push({ 'event': 'simulation-form-submitted', 'weight': 5, 'language': language });
        }
      } else {
        alert(t('An error has occurred. Please try again later.'))
        try {
          const result = await response.json();
          console.error(result);
        } catch (e) {
          console.error(e);
        }
      }

      setFormLoading(false);
    } catch (e) {
      console.error(e);
      setFormLoading(false);
    }
  };

  return (
    <>
      <Simulator isBlurred={!showSimulator} />

      {!showSimulator && <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
          style={{ zIndex: 1200 }}
        >
          <div className="h-auto relative w-auto max-w-3xl mx-4" style={{ width: 390 }}>
            {/*content*/}
            <div className="h-auto border-0 shadow-lg relative flex flex-col w-full bg-white rounded-md outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5">
                <div className="text-center">
                  <h3 className="text-xl font-semibold">
                    {t("Discover your new face")}
                  </h3>
                  <h5 className="text-sm font-light">
                    {t("Fill out the form below to access the Neoris simulator and try our exclusive colors for free.")}
                  </h5>
                </div>
              </div>

              {!formSuccess && <form onSubmit={(e) => handleSubmitForm(e)}>
                {/*body*/}
                <div className="relative px-6 flex-auto">
                  <div>
                    <label>
                      {t("Firstname")}
                      <span className="text-red-500"> *</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                      value={formData.firstname}
                      required
                      className="block w-full border-0 py-2 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600"
                    />
                  </div>
                  <div className="mt-4">
                    <label>
                      {t("Lastname")}
                      <span className="text-red-500"> *</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
                      value={formData.lastname}
                      required
                      className="block w-full border-0 py-2 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600"
                    />
                  </div>
                  <div className="mt-4">
                    <label>
                      {t("Email")}
                      <span className="text-red-500"> *</span>
                    </label>
                    <input
                      type="email"
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      value={formData.email}
                      required
                      className="block w-full border-0 py-2 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600"
                    />
                  </div>
                  <div className="mt-4">
                    <label>
                      {t("Country")}
                      <span className="text-red-500"> *</span>
                    </label>
                    <select
                      onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                      value={formData.country}
                      required
                      className="block w-full border-0 py-2 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600"
                    >
                      {countries.map((country, idx) =>
                        <option key={`country-${idx}`} value={country}>{country}</option>
                      )}
                    </select>
                  </div>
                  <p className="my-4 text-blueGray-500 text-sm leading-relaxed">
                    {t("We respect your privacy. Neoris is committed to never selling your information to third parties. To learn more, visit our")}{" "}
                    <a href="https://neoris-eyes.com/politique-de-confidentialite/" className="underline" target="_blank">{t("privacy policy")}</a>.
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center px-6 py-3">
                  <button
                    type="submit"
                    className={`w-full font-bold uppercase text-sm px-6 py-4 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                                      ${formLoading
                        ? 'bg-gray-300 cursor-not-allowed opacity-50'
                        : 'bg-black text-white '}
                                                  `}
                    disabled={formLoading}
                  >
                    {formLoading ? `${t("Loading")}...` : t("Access the simulator")}
                  </button>
                </div>
              </form>}
              {formSuccess &&
                <div className="bg-green-100 border-b-4 border-green-500 text-green-700 p-4" role="alert">
                  <p className="font-bold">{t("Thank you!")}</p>
                  <p>{formSuccess}</p>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>}
    </>
  );
}

export default App;
